import checkmark from "../assets/img/checkmark.svg";

interface IProps {
  checked?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

const CheckBox = (props: IProps) => {
  return (
    <div
      onClick={() => props.onChange?.(!props.checked)}
      className={`w-5 h-5 rounded cursor-pointer flex items-center justify-center min-w-[20px] transition-colors ${
        props.checked
          ? "bg-MainAccent"
          : "border-2 border-SecondaryAccent-corner"
      } ${props.className}  `}
    >
      {props.checked && <img src={checkmark} alt="✓" className="w-3 h-3" />}
    </div>
  );
};

export default CheckBox;
