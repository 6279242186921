import React from "react";

interface IProps {
  text?: string;
  isAttention?: boolean;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const Button = (p: IProps) => {
  const handleClick = () => {
    if (!p.disabled) {
      if (p.onClick) {
        p.onClick();
      }
    }
  };

  return (
    <button
      className={`${
        p.disabled
          ? "disabledButton"
          : p.isAttention
          ? "attentionButton"
          : "mainButton"
      } ${p.className}`}
      onClick={handleClick}
      disabled={p.disabled}
    >
      <p className={`flex items-center justify-center gap-2`}>
        {p.children}
        {p.text}
      </p>
    </button>
  );
};

export default Button;
