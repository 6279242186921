enum AppRoutes {
  About = "/about",
  Archive = "/archive",
  Basket = "/basket",
  BasketOrder = "/basket_order",
  Certificates = "/certificates",
  Command = "/command",
  Courses = "/courses",
  Feedback = "/feedback",
  Home = "/login",
  Learn = "/learn",
  Level = "/level",
  Main = "/",
  Order = "/order",
  Recovery = "/recovery",
  Registration = "/registration",
  ResetPassword = "/change",
  Session = "/session",
  Shop = "/shop",
  ShopItem = "/shopitem",
  Subscription = "/subscription",
  UserCabinet = "/user_cabinet",
  UserPage = "/user_page",
  Info = "/info",
  Auth = "/auth",
  Vebinar = "/vebinar",
  Create = "/design_systeme",
  CreateOne = "/journeytochina",
  CreateTwo = "/design_systeme/two",
  CreateThree = "/design_systeme/three",
  PromoRegistration = "/design_systeme_registrtion",
  PromoRegistrationFinal = "/design_systeme_registrtion_final",
  Welcome = "/welcome",
  PrizesForSystemepoints = "/prizes_for_systemepoints",
  PrizeOfFirstProject = "/register_systeme",
  ProjectRegistration = "/create-systeme",
  Object = "Object",
  Question = "/question",
  PromoCabinet = "/design_systeme_cabinet",
  PromoAdmn = "/design_admin"
}

export default AppRoutes;
