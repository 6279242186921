import Button from "../../components/Button";
import CheckBox from "../../components/CheckBox";
import ShopItemBasket from "../../components/ShopItemBasket";
import PRIZES, { formatShopImage } from "../../structure/prizes";


import left from "../../assets/img/left.svg";
import BasketAuthPlate from "../../components/BasketAuthPlate";
import { useEffect, useState } from "react";
import { AuthBasketPromoUserResp } from "../../network/PromoApi/promoInterfaces";
import { authBasketPromoUser, basketLogout } from "../../network/PromoApi/promoApi";
import { useNavigate } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import { useBasket } from "../../utils/BasketContext";

interface IProps {}

const BasketPage = (props: IProps) => {
  const navigate = useNavigate();
  const { basket, setItem, clearBasket } = useBasket();
  const [selected, setSelected] = useState<string[]>([]);
  const [user, setUser] = useState<AuthBasketPromoUserResp>();
  const [authError, setAuthError] = useState<boolean>(false);

  const totalPrice: number = selected.reduce(
    (sum, item) =>
      sum + (basket[Number(item)] ?? 0) * (PRIZES[Number(item)].point ?? 0),
    0
  );

  const readyOrder = selected.length > 0 && user && totalPrice <= user.points;

  const handleAuth = async (mail: string, id: string) => {
    authBasketPromoUser({
      id: id,
      mail: mail,
    }).then((res) => {
      if (res.error) {
        setAuthError(true);
      } else {
        setUser(res);
        setAuthError(false);
      }
    });
  };

  const clearSelected = () => {
    const newSelected = selected.filter((item) => basket[Number(item)] > 0);
    setSelected([...newSelected]);
  };

  const logout = () => {
    basketLogout().then(() => {
      setUser(undefined);
    });
  };

  const handleSelect = (item: string) => {
    setSelected((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  };

  const toOrder = () => {
    const selectedBasket: { [key: number]: number } = {};
    selected.forEach((item) => {
      selectedBasket[Number(item)] = basket[Number(item)];
    });
    navigate(AppRoutes.BasketOrder, {
      state: { basket: selectedBasket, user: user },
    });
  };

  useEffect(() => {
    authBasketPromoUser({}).then((res) => {
      if (!res.error) {
        setUser(res);
      }
    });

    setSelected(Object.keys(basket));
  }, []);

  return (
    <div className="flex justify-between items-stretch h-full  gap-20">
      <div className="h-full w-full">
        <p
          className="flex gap-2 mb-3 text-SecondaryAccent-dark text-sm font-normal cursor-pointer mt-6"
          onClick={() => navigate(AppRoutes.PrizesForSystemepoints)}
        >
          <img src={left} alt="" />
          Продолжить выбор призов
        </p>
        <p className="font-semibold text-4xl mb-10">Моя корзина</p>
        {Object.keys(basket).length > 0 ? (
          <>
            <div className="flex items-center gap-2 ml-6 mb-4">
              <CheckBox
                checked={selected.length === Object.keys(basket).length}
                onChange={() =>
                  setSelected(
                    selected.length === Object.keys(basket).length
                      ? []
                      : Object.keys(basket)
                  )
                }
              />
              Выбрать все
            </div>
            <div className="flex flex-col gap-4 w-full">
              {Object.keys(basket).map((item, i) => (
                <ShopItemBasket
                  key={i}
                  price={PRIZES[+item].point}
                  img={formatShopImage(PRIZES[+item].im)}
                  title={PRIZES[+item].name}
                  count={basket[Number(item)]}
                  selected={selected.includes(item)}
                  onSelect={() => handleSelect(item)}
                  setItem={(count: number) => {
                    setItem(count, +item);
                    clearSelected();
                  }}
                  onRemove={() => {
                    setItem(0, +item);
                    clearSelected();
                  }}
                />
              ))}
            </div>
            <p className="text-3xl font-semibold mt-8 mb-1">
              Итого: {totalPrice} SP
            </p>
            {user ? (
              user.points < totalPrice ? (
                <p className="text-error font-semibold text-sm mb-4">
                  Не хватает {totalPrice - user.points} SP
                </p>
              ) : (
                <p className="font-semibold text-sm mb-4">
                  Остаток ваших баллов SP:{" "}
                  <span className="text-MainAccent">
                    {user.points - totalPrice} SP
                  </span>
                </p>
              )
            ) : (
              <p className="text-error font-semibold text-sm mb-4">
                Подключитесь к кошельку SystemePoints
              </p>
            )}

            <Button
              text="Оформить"
              disabled={!readyOrder}
              className="mb-10 px-8 xy-3"
              onClick={toOrder}
            />
          </>
        ) : (
          <div className="3flex-1 justify-center items-center w-full">
            <p className="text-2xl text-center font-semibold mt-[30%] mb-1 text-SecondaryAccent">
              Ваша корзина пуста
            </p>
          </div>
        )}
      </div>
      <div className="flex flex-col h-full justify-start items-center pt-40">
        <BasketAuthPlate
          user={user}
          onAuth={handleAuth}
          error={authError}
          logout={logout}
          setError={setAuthError}
        />
      </div>
    </div>
  );
};

export default BasketPage;
