export interface Prize {
  im: number | string;
  name: string;
  point: number;
}


export function formatShopImage(img: number | string) {
  return `https://3l.systeme.ru/api/event_prises/${img}.jpg`;
}

const PRIZES: Prize[] = [
  { im: 50, name: "Стайлер Dyson", point: 2692 },
  { im: 51, name: "Стайлер Bork", point: 1731 },
  { im: 41, name: "Электрический самокат Tribe Himba", point: 577 },
  { im: 28, name: 'Монитор игровой HUAWEI 27"', point: 1154 },
  { im: 26, name: "Кофемашина автоматическая DeLonghi", point: 1923 },
  {
    im: 23,
    name: "Кофемашина капсульного типа Krups Dolce Gusto",
    point: 297,
  },
  {
    im: 24,
    name: "Кофемашина капсульного типа DeLonghi Nespresso",
    point: 654,
  },
  { im: 25, name: "Кофемашина автоматическая Philips", point: 1270 },
  { im: 86, name: "Кофеварка капельного типа Smeg", point: 1231 },
  { im: 87, name: "Кофеварка рожкового типа Smeg", point: 1685 },
  { im: 6, name: "Смарт-часы Samsung Galaxy Watch6", point: 731 },
  { im: 1, name: "Фитнес-браслет Xiaomi Mi Band 8 Золотистый", point: 154 },
  { im: 2, name: "Фитнес-браслет Xiaomi Mi Band 8 Черный", point: 154 },
  {
    im: 3,
    name: "Смарт-часы Samsung Galaxy Watch4 40mm, цвет серебро",
    point: 616,
  },
  {
    im: 4,
    name: "Смарт-часы Samsung Galaxy Watch4 44mm, черный",
    point: 539,
  },
  { im: 97, name: "Фитнес-браслет Garmin", point: 824 },
  { im: 11, name: "Портативная колонка JBL Pulse 5 Black", point: 981 },
  { im: 10, name: "Портативная колонка JBL Flip 6 Black", point: 481 },
  {
    im: 16,
    name: "Умная колонка Яндекс Станция Макс",
    point: 1347,
  },
  { im: 13, name: "Умная колонка Яндекс Станция Лайт", point: 192 },
  { im: 14, name: "Умная колонка Яндекс Станция Мини", point: 327 },
  { im: 15, name: "Умная колонка Яндекс Станция 2", point: 693 },
  { im: 68, name: "Караоке система JBL", point: 3270 },
  { im: 27, name: 'Монитор игровой Thunderobot 23.8"', point: 270 },
  { im: 17, name: "Беспроводные наушники с микрофоном JBL Tune", point: 174 },
  { im: 18, name: "Беспроводные наушники с микрофоном JBL Tune", point: 231 },
  { im: 20, name: "Наушники True Wireless Samsung Galaxy Buds", point: 347 },
  { im: 9, name: "Портативная колонка JBL Go", point: 185 },
  {
    im: "Отвертка",
    name: "Отвертка и набор бит Xiaomi Mi Precision",
    point: 77,
  },
  { im: 33, name: "Набор отверток c 3-х компонентной ручкой", point: 70 },
  {
    im: 35,
    name: "Набор шлицевых и крестовых отверток Jonnesway",
    point: 207,
  },
  { im: 34, name: "Набор отверток и насадок KRAFTOOL", point: 204 },
  { im: 36, name: "Универсальный набор Makita в кейсе", point: 281 },
  { im: 21, name: "Налобный фонарь NITECORE", point: 462 },
  { im: 38, name: "Ударная дрель Makita", point: 362 },
  { im: 37, name: "Аккумуляторная дрель-шуруповерт Makita", point: 712 },
  { im: 96, name: "Пылесос ручной Xiaomi", point: 385 },
  { im: 40, name: "Робот-пылесос", point: 616 },
  { im: 88, name: "Игровая клавиатура Razer", point: 127 },
  { im: 80, name: "Мышь беспроводная Logitech", point: 39 },
  { im: 29, name: "Игровая мышь Razer", point: 104 },
  { im: 31, name: "Игровая мышь Razer Basilisk", point: 270 },
  { im: 32, name: "Игровая мышь Razer Pro Click", point: 416 },
  { im: 22, name: "Массажный пистолет Xiaomi", point: 481 },
  {
    im: "Зарядное устройство",
    name: "Беспроводное зарядное устройство 10000мА",
    point: 154,
  },
  { im: 7, name: "Внешний жесткий диск Toshiba 1TB", point: 270 },
  { im: 95, name: "Внешний жесткий диск SSD Transcend 1TB", point: 539 },
  { im: "Кружка", name: "Кружка-хамелеон «Северное сияние»", point: 14 },
  { im: "Термос", name: "Термос «Confident»", point: 37 },
  { im: 44, name: "Электрочайник Moulinex", point: 104 },
  { im: 42, name: "Термопот Brayer", point: 347 },
  { im: 45, name: "Мультиварка Redmond", point: 289 },
  { im: 46, name: "Электрогриль Redmond SteakMaster", point: 266 },
  { im: 48, name: "Скоростной джип на радиоуправлении", point: 204 },
  {
    im: 52,
    name: "Деревянный пазл для взрослых и детей ~300 деталей",
    point: 29,
  },
  {
    im: 53,
    name: "Деревянный пазл для взрослых и детей ~700 деталей",
    point: 127,
  },
  { im: 54, name: "Пазл-картина ~2000 деталей", point: 397 },
  { im: 55, name: "Фотоаппарат моментальной печати", point: 577 },
  { im: 56, name: "Электронная книга Kindle", point: 597 },
  { im: 57, name: "Лазерное МФУ HP LaserJet", point: 1424 },
  { im: 58, name: "Умные весы Tefal", point: 193 },
  { im: 59, name: "ИБП BackSave BV", point: 385 },
  { im: 60, name: "Универсальное зарядное устройство Xiaomi", point: 58 },
  { im: 61, name: "Рюкзак", point: 243 },
  { im: 62, name: "Набор ArtGallery белый (спальня)", point: 485 },
  { im: 63, name: "Набор ArtGallery карбон (гостиная)", point: 624 },
  { im: 64, name: "Набор ArtGallery песочный (кухня)", point: 670 },
  { im: 65, name: "Набор Atlas Design белый (спальня)", point: 616 },
  { im: 66, name: "Набор Atlas Design карбон (гостиная)", point: 508 },
  { im: 67, name: "Набор Atlas Design песочный (кухня)", point: 531 },
  { im: 69, name: "Игровое компьютерное кресло Стандарт", point: 385 },
  { im: 70, name: "Игровое компьютерное кресло Люкс", point: 1539 },
  { im: 71, name: "Гамак", point: 62 },
  { im: 72, name: "Увлажнитель воздуха Xiaomi Lite", point: 174 },
  { im: 73, name: "Очиститель воздуха Xiaomi Smart", point: 1808 },
  { im: 74, name: "Сертификат Яндекс.Плюс на 1мес", point: 16 },
  { im: 75, name: "Сертификат Яндекс.Плюс на 1год", point: 115 },
  { im: 76, name: "Сумка-шоппер с лого Systeme Electric", point: 20 },
  { im: 77, name: "Вечный карандаш с лого Systeme Electric", point: 17 },
  {
    im: 78,
    name: "Светоотражающий брелок с лого Systeme Electric",
    point: 8,
  },
  {
    im: 79,
    name: "Карандаш чернографитовый с лого Systeme Electric",
    point: 3,
  },
  { im: 93, name: "Конструктор LEGO Architecture", point: 1654 },
  { im: 81, name: "Конструктор LEGO CREATOR", point: 35 },
  { im: 82, name: "Конструктор LEGO Friends", point: 289 },
  { im: 83, name: "Конструктор LEGO Classic", point: 104 },
  { im: 84, name: "Наушники накладные Marshall", point: 577 },
  { im: 85, name: "Напольная беговая дорожка Yamaguchi", point: 1924 },
  { im: 89, name: "Настольная лампа Bork", point: 962 },
  { im: 90, name: "Светильник настольный Xiaomi", point: 220 },
  { im: 91, name: "Пылесос ручной Dyson", point: 2577 },
  { im: 92, name: "Электрочайник Smeg", point: 885 },
  { im: 94, name: "Обед с директором департамента", point: 385 },
];

export default PRIZES;
