import trash from "../assets/img/trash.svg";
import CheckBox from "./CheckBox";
import Counter from "./Counter";
interface IProps {
  img: string;
  title: string;
  price: number;
  count: number;
  selected?: boolean;
  onSelect?: () => void;
  onRemove?: () => void;
  setItem?: (count: number) => void;
}

const ShopItemBasket = (props: IProps) => {
  return (
    <div className="flex gap-4 items-center justify-between p-6 border-[#E2E5EB]-corner border-2 rounded-md">
      <div className="flex justify-around items-center gap-10">
        <CheckBox checked={props.selected} onChange={props.onSelect} />
        <img src={props.img} alt="" className="w-20 h-20 object-cover" />

        <p className="text-base font-semibold max-w-[200px]">{props.title}</p>
      </div>
      <div className="flex justify-around items-center gap-10">
        <p className="text-base font-semibold text-MainAccent whitespace-nowrap">{props.price * props.count} SP</p>
        <Counter count={props.count} setCount={props.setItem} />
        <button onClick={props.onRemove}>
          <img src={trash} alt="trash" />
        </button>
      </div>
    </div>
  );
};

export default ShopItemBasket;
