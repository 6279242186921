interface IProps {
  img: string;
  title: string;
  price: number;
  count: number;
}

const ShopOrderItem = (props: IProps) => {
  return (
    <div className="flex gap-4 flex-col items-center justify-between w-full">
      <div className="flex gap-4 items-center w-full">
        <img
          src={props.img}
          alt=""
          className="w-20 h-20 min-w-[80px] object-contain"
        />
        <p className="text-xs font-semibold">{props.title}</p>
      </div>
      <div className="w-full flex justify-between">
        <p className="text-sm font-semibold text-SecondaryAccent">
          Кол-во: <span className="text-black">{props.count}</span>
        </p>

        <p className="text-sm font-semibold text-SecondaryAccent">
          Сумма: <span className="text-black">{props.price} SP</span>
        </p>
      </div>
      <div className="bg-SecondaryAccent-corner w-full h-[1px]"></div>
    </div>
  );
};

export default ShopOrderItem;
