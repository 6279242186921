import { useCallback } from "react";
import minus from "../assets/img/minus.svg";
import plus from "../assets/img/plus.svg";

interface IProps {
  count?: number;
  setCount?: (count: number) => void;
}

const Counter = (props: IProps) => {
  const count = props.count ?? 0;
  const addCount = () => {
    props.setCount?.(count + 1);
  };

  const removeCount = () => {
    props.setCount?.(count - 1);
  };

  const onChangeCount = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(e.currentTarget.value))
        props.setCount?.(+e.currentTarget.value);
    },
    [props]
  );
  return (
    <div className="flex items-center justify-between gap-2">
      <button
        onClick={removeCount}
        className="cursor-pointer"
        aria-label="Decrease count"
      >
        <img src={minus} alt="-" />
      </button>
      <div>
        <input
          type="text"
          value={count}
          onChange={onChangeCount}
          className="outline-none focus:outline-none border-2 border-SecondaryAccent-corner rounded-lg text-center w-20 h-12 "
        />
      </div>
      <button
        onClick={addCount}
        className="cursor-pointer"
        aria-label="Increase count"
      >
        <img src={plus} alt="+" />
      </button>
    </div>
  );
};

export default Counter;
