import trash from "../assets/img/trash.svg";
interface IProps {
  img: string;
  title: string;
  price: number;
  onRemove?: () => void;
}

const ShopItem = (props: IProps) => {
  return (
    <div className="flex gap-4 items-center justify-between">
      <img src={props.img} alt="" className="w-20 h-20 min-w-[80px] object-contain" />
      <div className="w-full flex flex-col gap-1">
        <p className="text-sm font-semibold">{props.title}</p>
        <p className="text-xs font-semibold text-MainAccent">{props.price} SP</p>
      </div>
      <button onClick={props.onRemove}>
        <img src={trash} alt="trash" />
      </button>
    </div>
  );
};

export default ShopItem;
