import React, { useEffect, useState } from "react";
import SiteLogo from "./SiteLogo";
import UserLevel from "./UserLevel";
import Command from "./Command";
import UserProfile from "./UserProfile";
import { GetHeaderRes } from "../network/UserApi/userInterfaces";
import { getHeader } from "../network/UserApi/userApi";
import { baseURL } from "../config";

const TopNav = () => {

  const [user, setUser] = useState<GetHeaderRes>();
  useEffect(() => {
    getHeader().then((r) => {
      setUser(r);
    });
  }, []);
  return (
    <div>
      <div className="topNavMain">
        <SiteLogo />
        <UserLevel
          level={user?.user_level || 0}
          nextLevel={user?.user_next_level_points || 0}
          currentExp={user?.user_level_experience || 0}
          coins={user?.user_coins || 0}
        />
        <Command />

        <UserProfile
          picture={baseURL + user?.user_avatar || ""}
          username={user?.user_name || "user"}
        />
      </div>
    </div>
  );
};

export default TopNav;
