import Button from "./Button";

import { useCallback } from "react";
import Counter from "./Counter";

interface IProps {
  children?: string;
  className?: string;
  count?: number;
  setCount?: (count: number) => void;
}

const CountButton = (props: IProps) => {
  const { count: initialCount = 0 } = props;
  const count = initialCount ?? 0;

  return (
    <div className={`${props.className}`}>
      {count === 0 ? (
        <Button
          text={props.children ?? ""}
          className={`text-base ${props.className}`}
          onClick={() => props.setCount?.(1)}
        />
      ) : (
        <Counter count={count} setCount={props.setCount} />
      )}
    </div>
  );
};

export default CountButton;
