import React, { createContext, useContext, useEffect, useState } from "react";

const STORAGE_BASKET_KEY = "basket";

interface BasketContextType {
  basket: { [key: number]: number };
  setItem: (count: number, id: number) => void;
  clearBasket: () => void;
}

const BasketContext = createContext<BasketContextType | undefined>(undefined);

export const BasketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [basket, setBasket] = useState<{ [key: number]: number }>({});

  const getBasket = () => {
    const rawBasket = localStorage.getItem(STORAGE_BASKET_KEY);
    setBasket(rawBasket ? JSON.parse(rawBasket) : {});
  };

  const clearBasket = () => {
    setBasket({});
    localStorage.removeItem(STORAGE_BASKET_KEY);
  };

  const setItem = (count: number, id: number) => {
    const updatedBasket = { ...basket, [id]: count };
    if (count === 0) {
      delete updatedBasket[id];
    }
    setBasket({ ...updatedBasket });
    localStorage.setItem(STORAGE_BASKET_KEY, JSON.stringify(updatedBasket));
  };

  useEffect(() => {
    getBasket();
  }, []);

  return (
    <BasketContext.Provider value={{ basket, setItem, clearBasket }}>
      {children}
    </BasketContext.Provider>
  );
};

export const useBasket = () => {
  const context = useContext(BasketContext);
  if (context === undefined) {
    throw new Error("useBasket must be used within a BasketProvider");
  }
  return context;
}; 