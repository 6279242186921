import { useState } from "react";
import Button from "./Button";
import BasketIcon from "./Create/icons/BasketIcon";
import Menu from "./Menu";
import BasketMenu from "./BasketMenu";

interface IProps {
  count?: number;
  items?: { [key: number]: number };
  isAuth?: boolean;
  onClear?: () => void;
  setItem?: (count: number, id: number) => void;

}

const BasketButton = (props: IProps) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  return (
    <div className="flex items-center">
      {!props.isAuth ? (
        <Button
        onClick={() => setMenuOpen(true)}
        className="bg-transparent rounded-full border-2 border-MainAccent text-MainAccent hover:bg-transparent"
      >
          <BasketIcon /> Корзина ({props.count})
        </Button>
      ) : (
        <button
          onClick={() => setMenuOpen(true)}
          className="relative"
        >
          <BasketIcon size={30} /> <p className="absolute -top-1/3 -right-1/3 bg-MainAccent text-white rounded-full w-6 h-6 flex items-center justify-center">{props.count}</p>
        </button>
      )}

      <Menu visible={menuOpen} onClose={() => setMenuOpen(false)}>
        <BasketMenu
          items={props.items}
          onClear={props.onClear}
          setItem={props.setItem}
        />
      </Menu>
    </div>
  );
};

export default BasketButton;
