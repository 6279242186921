import close from "../assets/img/close-icon.svg";
import ReactInputMask from "react-input-mask";

interface IProps {
  value?: string;
  error?: string;
  prefix?: string;
  placeholder?: string;
  className?: string;
  mask?: string;
  onChange?: (e: string) => void;
}

const BasketInput = (props: IProps) => {
  return (
    <div className={props.className}>
      <div className="relative flex gap-2 items-center p-2 border-2 border-SecondaryAccent-one placeholder:text-SecondaryAccent-one rounded-lg">
        {props.value && props.placeholder && (
          <p className="absolute left-0 -top-2/3  text-sm font-semibold text-SecondaryAccent">
            {props.placeholder}
          </p>
        )}
        {props.prefix && (
          <p className=" font-semibold text-SecondaryAccent">
            {props.prefix}
          </p>
        )}
        {props.mask ? (
        <ReactInputMask
          mask={props.mask ?? ""}
          value={props.value}
          onChange={(e: any) => {
            console.log(e.target.value);
            props.onChange && props.onChange(e.target.value);
          }}
        >
          {(inputProps: any) => (
            <input
              {...inputProps}
              type="text"
              className="w-full h-full outline-none focus:outline-none"
              placeholder={props.placeholder}
            />
          )}
          </ReactInputMask>
        ) : (
          <input
            type="text"
            className="w-full h-full outline-none focus:outline-none"
            placeholder={props.placeholder}
            value={props.value}
            onChange={(e) => {
              props.onChange && props.onChange(e.target.value);
            }}
          />
        )}
        {props.value && (
          <button
            className="absolute right-2 top-1/2 -translate-y-1/2"
            onClick={() => {
              props.onChange && props.onChange("");
            }}
          >
            <img src={close} alt="" />
          </button>
        )}
      </div>
      {props.error && (
        <p className="text-CloseDeadlineAccent text-sm font-semibold">
          {props.error}
        </p>
      )}
    </div>
  );
};

export default BasketInput;
