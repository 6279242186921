import { useEffect, useState } from "react";
import BasketInput from "./BasketInput";
import Button from "./Button";
import MainInput from "./welcome/MainInput";
import { isValidEmail } from "../utils/data";
import { AuthBasketPromoUserResp } from "../network/PromoApi/promoInterfaces";

interface IProps {
  user?: AuthBasketPromoUserResp;
  error?: boolean;
  onAuth: (mail: string, id: string) => void;
  logout: () => void;
  setError: (error: boolean) => void;
}

const BasketAuthPlate = (props: IProps) => {
  const [id, setId] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    props.setError(false);
  }, [id, email]);

  return !props.user ? (
    <div className="flex flex-col gap-10 max-w-[400px] bg-white rounded-3xl shadow-lg p-6">
      <div>
        <p className="text-xl font-semibold mb-2">Кошелек SystemePoints</p>
        <p className="text-sm font-normal text-SecondaryAccent">
          Подключитесь к кошельку SystemePoints для проверки ваших баллов и
          оформления заказа. Введите e-mail, который был указан при регистрации
          проектов
        </p>
      </div>
      <BasketInput value={id} placeholder="ID участника" onChange={setId} />
      <div>
        <BasketInput
          value={email}
          className="mb-2"
          placeholder="E-mail участника"
          onChange={setEmail}
        />
        {props.error && (
          <p className="text-error text-sm font-semibold">
            E-mail или ID участника не найдены
          </p>
        )}
      </div>
      <Button
        disabled={!(id !== "" && isValidEmail(email))}
        onClick={() => props.onAuth(email.toLowerCase(), id.toUpperCase())}
      >
        Войти
      </Button>
    </div>
  ) : (
    <div className="flex flex-col gap-2 min-w-[400px] bg-white rounded-3xl shadow-lg p-6">
      <p className="text-xl font-semibold">Кошелек SystemePoints</p>
      <p className="text-sm font-normal text-SecondaryAccent">ID участника</p>
      <p className="text-sm font-normal">{props.user.id}</p>
      <p className="text-sm font-normal text-SecondaryAccent">
        Почта участника
      </p>
      <p className="text-sm font-normal">{props.user.mail}</p>
      <button className="text-start" onClick={props.logout}>
        <p className="text-sm font-normal text-MainAccent mt-3 mb-4">
          Выйти из кошелька
        </p>
      </button>
      <div className="bg-SecondaryAccent-corner w-full h-[1px]"></div>
      <div className="flex justify-between mt-4">
        <p className="text-sm font-semibold text-SecondaryAccent">Ваши баллы</p>
        <p className="text-sm font-semibold text-MainAccent">
          {props.user.points} SP
        </p>
      </div>
    </div>
  );
};

export default BasketAuthPlate;
