import { useLocation } from "react-router-dom";
import left from "../../assets/img/left.svg";
import BasketInput from "../../components/BasketInput";
import BasketModal from "../../components/BasketModal";
import BasketOrderPlate from "../../components/BasketOrderPlate";
import Button from "../../components/Button";
import CheckBox from "../../components/CheckBox";
import { useEffect, useState } from "react";
import { isValidNumber } from "../../utils/data";
import { basketConfirm, basketOrder } from "../../network/PromoApi/promoApi";
import PRIZES from "../../structure/prizes";
import { AuthBasketPromoUserResp } from "../../network/PromoApi/promoInterfaces";
import AppRoutes from "../../structure/routes";
import { useNavigate } from "react-router-dom";
import { useBasket } from "../../utils/BasketContext";

interface IProps {}

const OrderBasketPage = (props: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { clearBasket } = useBasket();

  const [user, setUser] = useState<AuthBasketPromoUserResp>();
  const [basket, setBasket] = useState<{ [key: number]: number }>();

  const [name, setName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [house, setHouse] = useState<string>("");
  const [flat, setFlat] = useState<string>("");

  const [isConditions, setIsConditions] = useState<boolean>(false);
  const [isRules, setIsRules] = useState<boolean>(false);
  const [isAgreement, setIsAgreement] = useState<boolean>(false);

  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [alreadyModal, setAlreadyModal] = useState<boolean>(false);

  const realPhone = phone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ","").replaceAll("+7", "");
  console.log(realPhone);
  console.log(isValidNumber(realPhone));
  
  const readyOrder =
    isValidNumber(realPhone) &&
    name.length > 0 &&
    address.length > 0 &&
    city.length > 0 &&
    street.length > 0 &&
    house.length > 0 &&
    isConditions &&
    isRules;

  console.log(name);

  const handleSubmit = () => {

    if (!user || !basket) return;

    basketOrder({
      name,
      phone,
      address,
      city,
      street,
      house,
      isAgreement,
      flat,
      mail: user.mail,
      items: Object.keys(basket).map((item) => ({
        id: Number(item),
        count: basket[Number(item)],
        price: PRIZES[Number(item)].point,
        name: PRIZES[Number(item)].name,
      })),
      id: user.id,
    }).then((res) => {
      if (res.status === "no") navigate(AppRoutes.Basket);
      clearBasket();
      setConfirmModal(true);
    });
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      basketConfirm(code).then((res) => {
        if (res.status === "no") navigate(AppRoutes.Basket);
        setAlreadyModal(true);
      });
    }
    if (location.state) {
      const localUser = location.state.user;
      const localBasket = location.state.basket;
      if (!localUser || !localBasket) navigate(AppRoutes.Basket);

      setUser(localUser);
      setBasket(localBasket);
    }
  }, []);

  return (
    <div className="flex justify-between gap-20">
      <div className="flex flex-col gap-6 pb-6">
        <p
          className="flex gap-2 mb-3 text-SecondaryAccent-dark text-sm font-normal cursor-pointer mt-6"
          onClick={() => navigate(AppRoutes.Basket)}
        >
          <img src={left} alt="" />
          Корзина
        </p>
        <p className="font-semibold text-4xl mb-10">
          Заявка на получение призов
        </p>
        <div className="flex flex-col gap-12">
          <div className="flex gap-8 items-stretch justify-normal">
            <BasketInput
              className="w-full"
              placeholder="Имя и фамилия"
              value={name}
              onChange={(e) => setName(e)}
            />
            <BasketInput
              className="w-full"
              placeholder="Мобильный телефон для связи"
              value={phone}
              mask="+7 (999) 999-99-99"
              onChange={(e) => setPhone(e)}
            />
          </div>
          <div className="flex gap-8 items-stretch justify-normal">
            <BasketInput
              className="w-full"
              placeholder="Почтовый индекс"
              value={address}
              onChange={(e) => setAddress(e)}
            />
            <BasketInput
              className="w-full"
              placeholder="Город/Населенный пункт"
              value={city}
              onChange={(e) => setCity(e)}
            />
          </div>
          <div className="flex gap-8 items-stretch justify-normal">
            <BasketInput
              className="w-full"
              placeholder="Улица"
              value={street}
              onChange={(e) => setStreet(e)}
            />
            <div className="flex gap-8 items-stretch justify-normal w-full">
              <BasketInput
                className="w-full"
                placeholder="Номер дома"
                value={house}
                onChange={(e) => setHouse(e)}
              />
              <BasketInput
                className="w-full"
                placeholder="Квартира/офис"
                value={flat}
                onChange={(e) => setFlat(e)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex gap-2">
            <CheckBox
              checked={isConditions}
              onChange={() => setIsConditions(!isConditions)}
            />
            <p>
              Заполняя настоящую регистрационную форму, я даю согласие
              Акционерному обществу «СИСТЭМ ЭЛЕКТРИК» (адрес: 127018, Г.МОСКВА,
              УЛ. ДВИНЦЕВ, Д. 12, К. 1, ЭТАЖ 6 ПОМ I КОМ 15) на обработку
              содержащихся в ней персональных данных в соответствии с условиями
              и порядком обработки моих персональных данных, установленных{" "}
              <a
                target="_blank"
                href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                className="text-MainAccent"
              >
                Политикой
              </a>{" "}
              в отношении обработки персональных данных и сведениями о
              реализуемых требованиях к защите персональных данных, в целях
              участия в акции <b>«Развивай SYSTEME»</b>, согласно{" "}
              <a
                target="_blank"
                href="https://workspace.systeme.ru/s/9ZsF4iY32tzdJcy"
                className="text-MainAccent"
              >
                Условиям акции
              </a>
              , акцептом которых является заполнение данной формы.
            </p>
          </div>
          <div className="flex gap-2">
            <CheckBox
              checked={isRules}
              onChange={() => setIsRules(!isRules)}
              className="mt-1"
            />
            <p>
              Подтверждаю, что ознакомлен и согласен с{" "}
              <a
                target="_blank"
                href="https://workspace.systeme.ru/s/9ZsF4iY32tzdJcy"
                className="text-MainAccent"
              >
                Правилами акции
              </a>
            </p>
          </div>
          <div className="flex gap-2">
            <CheckBox
              checked={isAgreement}
              onChange={() => setIsAgreement(!isAgreement)}
              className="mt-1"
            />
            <p>
              Заполняя настоящую регистрационную форму, я даю согласие АО
              «СИСТЭМ ЭЛЕКТРИК» (адрес: 127018, г. Москва, ул. Двинцев, д. 12,
              к. 1, этаж 6 пом I ком 15. Далее – АО СЭ) на обработку
              содержащихся в ней персональных данных с правом передачи третьим
              лицам, привлекаемым АО СЭ в целях организации и осуществления
              маркетинговых коммуникаций со мной путем проведения опросов,
              исследований, предоставления информации об услугах и продуктах АО
              CЭ, направления специальных предложений, приглашений на
              мероприятия, организуемые АО СЭ или при участии АО СЭ, продвижения
              услуг и продуктов АО СЭ, в том числе посредством электронной почты
              и телефонных звонков, в соответствии с условиями и порядком,
              установленных{" "}
              <a
                href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
                className="text-MainAccent"
                target="_blank"
              >
                Политикой в отношении обработки персональных данных
              </a>{" "}
              и сведениями о реализуемых требованиях к защите персональных
              данных акцептом которых является заполнение данной формы.
            </p>
          </div>
          <p>
            Налоговым агентом в отношении победителей настоящего мероприятия
            выступает ООО «РЭД Эдженси» (ИНН 7714871694, ОГРН 1127746333912),
            которое самостоятельно связывается с победителями, запрашивает у них
            необходимые данные для предоставления в налоговые органы и уплаты
            налога за победителя, согласно требованиям закона.
          </p>
          <Button disabled={!readyOrder} onClick={handleSubmit}>
            Отправить заявку
          </Button>
        </div>
      </div>
      <BasketOrderPlate basket={basket} />
      <BasketModal
        open={confirmModal}
        title="Необходимо подтверждение"
        onClose={() => {
          navigate(AppRoutes.Basket);
        }}
      >
        На вашу почту {user?.mail} отправлено письмо. Перейдите по ссылке
        из письма для подтверждения заявки на получение призов
      </BasketModal>

      <BasketModal
        isIcon
        open={alreadyModal}
        title="Заявка подтверждена"
        onClose={() => {
          navigate(AppRoutes.Basket);
        }}
      >
        Ваша заявка принята в обработку. В течение месяца с вами свяжется
        менеджер компании Red Agency для согласования деталей вручения призов. В
        случае возникновения вопросов, пожалуйста, пишите на почту{" "}

        <a href="mailto:3l@systeme.ru" className="text-MainAccent">
          3l@systeme.ru
        </a>
      </BasketModal>
    </div>
  );
};

export default OrderBasketPage;
