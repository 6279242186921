/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    screens: {
      xl: { max: "1279px" },
      // => @media (max-width: 1279px) { ... }

      lg: { max: "1025px" },
      // => @media (max-width: 1023px) { ... }

      md: { max: "769px" },
      // => @media (max-width: 767px) { ... }

      sm: { max: "450px" },
      // => @media (max-width: 639px) { ... }
    },
    extend: {
      screens: {
        tall: { raw: "(max-height: 1000px) and ( min-width: 1000px)" },
      },
      backgroundImage: {
        landingFade:
          "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8575805322128851) 5%, rgba(255,255,255,1) 15%, rgba(255,255,255,1) 100%)",
      },
      colors: {
        MainAccent: {
          DEFAULT: "#00AC86",
          hover: "#08C198",
          click: "#029D7A",
          dark: "#005E4A",
        },
        DarkAccent: {
          DEFAULT: "#202020",
          hover: "#303030",
          click: "#111111",
        },
        SecondaryAccent: {
          dark: "#626469",
          DEFAULT: "#9FA0A4",
          hover: "#878588",
          click: "#888888",
          one: "#C4C4C4",
          light: "#E5E5E5",
          veryLight: "#F5F5F5",
          table: "#424449",

          corner: "#D0D2D2",
        },
        CloseDeadlineAccent: {
          DEFAULT: "#EB6A72",
          hover: "#F65151",
          click: "#DA3C3C",
        },
        MainDeadlineAccent: {
          DEFAULT: "#FFAF14",
          hover: "#FFC24B",
          click: "#FFA800",
          transparent: "#FFEECC",
        },
        ChiefAccent: {
          DEFAULT: "#0DB2D7",
        },
        error: "#ED6972",
      },
      boxShadow: {
        recomendation: "0px 0px 6px rgba(0, 0, 0, 0.25)",
        recomendationHover: "0px 0px 14px rgba(0, 0, 0, 0.25)",
        plate: "0px 0px 20px rgba(0, 0, 0, 0.1)",
      },
      fontFamily: {
        opensans: ["Open Sans"],
        notosans: ["Noto Sans"],
      },
      borderRadius: {
        mainButton: "18px",
      },
    },
  },
  plugins: [],
};
