import Button from "../../components/Button";
import down from "../../assets/img/promoDown.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppRoutes from "../../structure/routes";
import ModalWrapper from "../../components/ModalWrapper";
import InputForm from "../../components/InputForm";
import { shopOrderReq } from "../../network/PromoApi/promoInterfaces";
import { orderPromoShop } from "../../network/PromoApi/promoApi";
import { request } from "http";
import CountButton from "../../components/CountButton";
import BasketButton from "../../components/BasketButton";
import PRIZES, { formatShopImage, Prize } from "../../structure/prizes";
import { useBasket } from "../../utils/BasketContext";

//sdaad
//sdasd

const REQUEST_INFO_DEFAULT = {
  name: "",
  userId: "",
  phone: "",
  delivery: "",
  product: "",
  price: 0,
};

const PrizesForSystemepoints = () => {
  const { basket, setItem, clearBasket } = useBasket();

  const [prizes, setPrizes] = useState(PRIZES);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [prizesItems, setPrizesItems] = useState<any>();
  const [selectedPrize, setSelectedPrize] = useState<Prize | undefined>();
  const [requestInfo, setRequestInfo] =
    useState<shopOrderReq>(REQUEST_INFO_DEFAULT);

  const [visibleResponseModal, setVisibleResponseModal] =
    useState<boolean>(false);

  const selector = document.getElementById("selector");

  const setValueByKey = (key: string, value: string) => {
    setRequestInfo((r) => {
      (r as any)[key] = value;
      return { ...r };
    });
  };

  const isOneKeyNull = (object: any) => {
    for (let key in object) {
      if (!object[key]) return true;
    }
    return false;
  };

  const setPrize = (prize: Prize) => {
    setSelectedPrize(prize);
    setRequestInfo({ ...requestInfo, product: prize.name, price: prize.point });
  };

  useEffect(() => {
    if (selectedFilter === "По убыванию цены") {
      setPrizes(prizes.sort((a, b) => b.point - a.point));
    }
    if (selectedFilter === "По возрастанию цены") {
      setPrizes(prizes.sort((a, b) => a.point - b.point));
    }
    if (selectedFilter === "По умолчанию") {
      setPrizes(PRIZES);
    }
  }, [selectedFilter]);

  useEffect(() => {
    const prizesList = prizes.map((pr, i) => {
      const maxLenght = 95;
      let slisedName = undefined;
      if (pr.name.length > maxLenght) {
        slisedName = pr.name.slice(0, maxLenght) + "...";
      }
      return (
        <div
          key={i}
          className="flex flex-col justify-between w-48 h-[350px] mx-8 mb-16"
        >
          <div className="flex-1 flex flex-col items-center">
            <div className="w-full flex justify-center h-48 mb-[16px]">
              <img
                className="max-h-48 object-cover"
                src={formatShopImage(pr.im)}
                alt=""
              />
            </div>
            <p className="text-center">{slisedName || pr.name}</p>
          </div>
          <div className="flex justify-center">
            <CountButton
              className="w-full"
              count={basket[i]}
              setCount={(count) => setItem(count, i)}
            >{`${pr.point} SystemePoints`}</CountButton>
          </div>
        </div>
      );
    });
    setPrizesItems(prizesList);
  }, [prizes, selectedFilter, basket]);

  return (
    <div className="flex flex-col items-center sm:mt-4 sm:px-4 mt-16">
      <div className={"w-[100%] mb-16"}>
        <div className="flex items-center justify-between">
          <p className={`base font-bold text-[26px] leading-[35px]`}>
            Призы в обмен на баллы SystemePoints{" "}
          </p>
          <BasketButton
            count={Object.keys(basket).length}
            items={basket}
            onClear={clearBasket}
            setItem={setItem}
          />
        </div>
        <p className={"my-5"}>
          Обменивайте полученные за участие в акции баллы SystemePoints на
          ценные призы! <br />
          Каждый участник сможет получить один или несколько призов в рамках
          накопленных баллов за весь период проведения акции. <br />
          Обмен баллов на призы будет доступен до 17 февраля 2025 г.
        </p>
        <b>
          Изображения призов приведены для примера и могут отличаться от
          фактических.
        </b>
        {/* <div className="flex flex-wrap mt-5">
          <Link
            to={AppRoutes.PromoRegistration}
            className="underline hover:no-underline"
          >
            Регистрируйте больше проектов,
          </Link>
          <p> чтобы получить желаемый приз</p>
        </div> */}
        <p className="mb-5">Если у вас возникли вопросы, пожалуйста, пишите на почту <a href="mailto:3l@systeme.ru" className="underline hover:no-underline text-MainAccent">3l@systeme.ru</a></p>
        <div
          className="text- flex items-center relative px-4 rounded-3xl h-12 w-52 group border-MainAccent border border-footer text-[14px] leading-4 text-SecondaryAccent-dark cursor-pointer"
          tabIndex={0}
          id="selector"
        >
          <span id="selectedPrise">
            {!selectedFilter ? "Сортировать" : selectedFilter}
          </span>
          <img
            src={down}
            className="absolute right-4 transition-all  group-focus:-scale-y-100  group-focus:transform"
            alt=""
          ></img>
          <div className="hidden z-20 group-focus:flex w-full mt-1 flex-col absolute bg-white top-full left-0 p-6 shadow-plate">
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={() => {
                if (selector) {
                  selector.blur();
                }
                setSelectedFilter("По умолчанию");
              }}
            >
              По умолчанию
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={() => {
                if (selector) {
                  selector.blur();
                }
                setSelectedFilter("По возрастанию цены");
              }}
            >
              По возрастанию цены
            </p>
            <p
              className="text-sm base text-unselected hover:text-selected cursor-pointer py-2 option"
              onClick={() => {
                if (selector) {
                  selector.blur();
                }
                setSelectedFilter("По убыванию цены");
              }}
            >
              По убыванию цены
            </p>
          </div>
        </div>
      </div>
      <div className={"w-full flex flex-wrap sm:justify-center sm:mb-5 mb-14"}>
        {prizesItems}
      </div>
      <div className="flex h-14 w-96 sm:w-full mb-14">
        <Link className="flex flex-1" to={AppRoutes.PromoRegistration}>
          <Button
            className="flex-1 rounded-[30px]"
            text="Зарегистрировать проект"
          ></Button>
        </Link>
      </div>
      <ModalWrapper
        header={"Заполните заявку"}
        text={``}
        mainClass="min-w-[40%]"
        onClose={() => {
          setSelectedPrize(undefined);
        }}
        visible={selectedPrize !== undefined}
      >
        <InputForm
          placeholder="Имя и фамилия"
          required
          onChange={(v) => {
            setValueByKey("name", v);
          }}
        />
        <InputForm
          placeholder="Ваш ID участника акции"
          required
          onChange={(v) => {
            setValueByKey("userId", v);
          }}
        />
        <InputForm
          placeholder="Мобильный телефон для связи"
          required
          onChange={(v) => {
            setValueByKey("phone", v);
          }}
        />
        <InputForm
          placeholder="Адрес доставки (укажите почтовый индекс, город, улицу, номер дома, квартиру или офис)"
          required
          onChange={(v) => {
            setValueByKey("delivery", v);
          }}
        />
        <div className="w-full flex items-center">
          <Button
            text={"Отправить"}
            className="mx-auto"
            disabled={isOneKeyNull(requestInfo)}
            onClick={() => {
              orderPromoShop(requestInfo).then((r) => {
                if (r.status === "ok") {
                  setSelectedPrize(undefined);
                  setRequestInfo({ ...REQUEST_INFO_DEFAULT });
                  setVisibleResponseModal(true);
                }
              });
            }}
          />
        </div>
      </ModalWrapper>
      <ModalWrapper
        header={"Ваша заявка оформлена"}
        text={
          "Мы свяжемся с Вами по электронной почте и сообщим детали для вручения подарка"
        }
        visible={visibleResponseModal}
        onClose={() => {
          setVisibleResponseModal(false);
        }}
      ></ModalWrapper>
    </div>
  );
};

export default PrizesForSystemepoints;
