import "./App.css";
import "./index.css";
import { AuthProvider } from "./Providers/PromoUserProvider";
import PageRouter from "./PageRouter";
import { BrowserRouter } from "react-router-dom";
import { BasketProvider } from "./utils/BasketContext";

function App() {
  return (
    <AuthProvider>
      <BasketProvider>
        <BrowserRouter>
          <PageRouter />
        </BrowserRouter>
      </BasketProvider>
    </AuthProvider>
  );
}

export default App;
