import close from "../assets/img/close-icon.svg";
import success from "../assets/img/system-icon.svg";

interface IProps {
  title?: string;
  children?: React.ReactNode;

  open?: boolean;
  isIcon?: boolean;

  onClose?: () => void;
}

const BasketModal = (props: IProps) => {
  if (!props.open) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center">
      <div className="absolute top-0 left-0 w-full h-full bg-black/50 flex justify-center items-center"></div>
      <div className="relative flex flex-col gap-4 justify-center max-w-lg px-6 py-8 z-10 bg-white rounded-3xl shadow-lg p-6">
        <button className="absolute top-4 right-6" onClick={props.onClose}>
          <img src={close} alt="" />
        </button>
        <p className="flex items-center justify-center gap-2 text-lg text-center font-semibold text-MainAccent">
          {props.isIcon && <img src={success} alt="" />}
          {props.title}
        </p>
        <p className="text-sm text-center font-semibold">{props.children}</p>
      </div>
    </div>
  );
};

export default BasketModal;
