import { useNavigate } from "react-router-dom";
import PRIZES, { formatShopImage } from "../structure/prizes";
import AppRoutes from "../structure/routes";
import Button from "./Button";
import BasketIcon from "./Create/icons/BasketIcon";
import ShopItem from "./ShopItem";

interface IProps {
  items?: { [key: number]: number };
  onClear?: () => void;
  setItem?: (count: number, id: number) => void;
}

const BasketMenu = (props: IProps) => {
  const navigate = useNavigate();
  
  const items = props.items ?? {};

  const totalPrice: number = Object.keys(items).reduce(
    (sum, key) => sum + items[Number(key)] * PRIZES[Number(key)].point,
    0
  );
  const totalCount: number = Object.keys(items).filter(
    (key) => items[Number(key)] > 0
  ).length;
  return (
    <div className="min-w-[432px] p-5">
      <p className="font-semibold text-lg">Выбранные призы ({totalCount})</p>
      <p className="font-semibold text-sm text-SecondaryAccent-dark">
        Общая стоимость <span className="text-black">{totalPrice} SP</span>
      </p>
      <div className="flex flex-col gap-4 mt-8">
        {Object.keys(items).map(
          (item, i) =>
            items[Number(item)] > 0 && (
              <ShopItem
                key={`I-${i}`}
                img={formatShopImage(PRIZES[Number(item)].im)}
                title={PRIZES[Number(item)].name}
                price={PRIZES[Number(item)].point}
                onRemove={() => props.setItem?.(0, Number(item))}
              />
            )
        )}
      </div>
      <div className="flex gap-4 mt-8">
        <Button
          className="rounded-full"
          onClick={() => navigate(AppRoutes.Basket)}
        >
          <BasketIcon strokeColor="white" /> В корзину
        </Button>
        <Button
          className="rounded-full  text-MainAccent border-2 border-MainAccent bg-transparent hover:bg-transparent"
          onClick={props.onClear}
        >
          Очистить
        </Button>
      </div>
    </div>
  );
};

export default BasketMenu;
