import logo from "../assets/img/welcome/promoLogo.svg";
import vk from "../assets/img/welcome/vkGreen.svg";
import youtube from "../assets/img/welcome/youtubeGreen.svg";
import telegram from "../assets/img/welcome/telegramGreen.svg";
import ok from "../assets/img/welcome/okGreen.svg";

interface IProps {}

const PromoFooter = (props: IProps) => {
  return (
    <div className="bg-MainAccent w-full flex sm:items-start sm:flex-wrap justify-between py-6 px-8">
      <img src={logo} alt="" className="sm:w-[34%]" />
      <div className="flex flex-col items-end gap-5 sm:w-[48%]">
        <div className={`flex flex-row items-start gap-3`}>
          <a
            target="_blank"
            className=" bg-white rounded-full"
            href="https://vk.com/systemeelectric"
            rel="noreferrer"
          >
            <img src={vk} alt="" className="sm:w-8 sm:h-8 w-10 h-10" />
          </a>
          <a
            target="_blank"
            className=" bg-white rounded-full"
            href="https://www.youtube.com/channel/UCfSQiFykGRQRb6dq1-mznrw"
            rel="noreferrer"
          >
            <img src={youtube} alt="" className="sm:w-8 sm:h-8 w-10 h-10" />
          </a>
          <a
            target="_blank"
            className=" bg-white rounded-full"
            href="https://t.me/systemeelectric_official"
            rel="noreferrer"
          >
            <img src={telegram} alt="" className="sm:w-8 sm:h-8 w-10 h-10" />
          </a>
          <a
            target="_blank"
            className=" bg-white rounded-full"
            href="https://ok.ru/group/70000000047930"
            rel="noreferrer"
          >
            <img src={ok} alt="" className="sm:w-8 sm:h-8 w-10 h-10" />
          </a>
        </div>
        <div className="sm:hidden flex gap-7 text-base font-bold text-white">
          <p>Обратная связь</p>
          <a
            target="_blank"
            href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
            rel="noreferrer"
          >
            Политика конфиденциальности
          </a>
          <p> © 2025 «Систэм Электрик»</p>
        </div>
      </div>
      <div className="sm:flex hidden flex-col  gap-1 mt-10 text-base font-bold text-white">
        <p>Обратная связь</p>
        <a
          target="_blank"
          href="https://systeme.ru/about/legal/politika-v-otnoshenii-obrabotki-personalnykh-dannykh"
          rel="noreferrer"
        >
          Политика конфиденциальности
        </a>
        <p> © 2025 «Систэм Электрик»</p>
      </div>
    </div>
  );
};

export default PromoFooter;
