import { useEffect, useRef } from 'react';
import close from '../assets/img/close-icon.svg';

interface IProps {
  children?: React.ReactNode;
  visible?: boolean;
  onClose?: () => void;
}

const Menu = (props: IProps) => {
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        props.onClose?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [props.onClose]);

  if (!props.visible) return null;

  return (
    <div className="relative z-50">
      <div ref={menuRef} className="absolute top-0 right-0 bg-white rounded-3xl shadow-xl">
        <button onClick={props.onClose} className="absolute top-6 right-6 ">
          <img src={close} alt="close" />
        </button>
        {props.children}
      </div>
    </div>
  );
};

export default Menu;
