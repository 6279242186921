import { useNavigate } from "react-router-dom";
import PRIZES, { formatShopImage } from "../structure/prizes";
import Button from "./Button";
import ShopOrderItem from "./ShopOrderItem";
import AppRoutes from "../structure/routes";

interface IProps {
  basket?: { [key: number]: number };
}

const BasketOrderPlate = (props: IProps) => {
  const navigate = useNavigate();
  const totalCost = Object.keys(props.basket || {}).reduce(
    (acc, item) => acc + PRIZES[+item].point * (props.basket as any)[+item],
    0
  );
  return props.basket ? (
    <div className="flex flex-col items-stretch gap-2 max-w-lg min-w-[312px] self-start mt-40 mb-20 bg-white rounded-3xl shadow-lg p-6">
      <p className="text-lg font-semibold">Выбранные призы</p>
      {Object.keys(props.basket).map((item) => (
        <ShopOrderItem
          img={formatShopImage(PRIZES[+item].im)}
          title={PRIZES[+item].name}
          price={PRIZES[+item].point}
          count={(props.basket as any)[+item]}
          key={+item}
        />
      ))}
      <div className="flex justify-between my-4">
        <p className="text-sm font-semibold text-SecondaryAccent">Сумма:</p>
        <p className="text-sm font-semibold text-black">{totalCost} SP</p>
      </div>
      <Button
        onClick={() => navigate(AppRoutes.Basket)}
        className="bg-transparent hover:bg-transparent text-MainAccent border-MainAccent border-2 rounded-full"
      >
        Вернуться в корзину
      </Button>
    </div>
  ) : null;
};

export default BasketOrderPlate;
